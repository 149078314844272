export const container = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      ease: 'easeIn',
    },
  },
};

export const item = {
  hidden: { opacity: 0, y: -50 },
  show: {
    opacity: 1,
    y: 0,
  },
};

export const contentContainer = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
  },
};

export const contentItem = {
  hidden: { opacity: 0, y: 0 },
  show: { opacity: 1, y: 0 },
};

export const containerPage = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      ease: 'easeIn',
    },
  },
};

export const itemPage = {
  hidden: { opacity: 0, y: 20 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: 'easeIn',
    },
  },
};
